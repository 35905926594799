import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { FormikErrors, useFormik } from 'formik';

import { convertRequestErrorToMap, useUpdateEffect } from '@tager/web-core';

import { colors } from '@/constants/theme';
import { media } from '@/utils/mixin';
import Button from '@/components/Button';
import Loader from '@/components/Loader';
import { CheckboxAgreement } from '@/components/Checkbox';
import CloseButton from '@/components/modals/shared/CloseButton';
import SuccessForm from '@/components/Forms/SuccessForm';
import TextInput from '@/components/TextInput';
import TextArea from '@/components/TextArea';
import { useLockBodyScroll } from '@/hooks/useLockBodyScroll';
import Select from '@/components/Select';
import { OptionType } from '@/typings/common';

import {
  getResultApplicationFormValues,
  initialApplicationFormValues,
  validationApplicationSchema,
} from './ApplicationFormModal.helpers';
import {
  ApplicationFormFieldsSchema,
  ApplicationFormModalProps,
} from './ApplicationFormModal.types';

function ApplicationFormModal({
  innerProps,
  closeModal,
}: ApplicationFormModalProps) {
  const [isFormSend, setFormSend] = useState(false);
  const [userAgreement, setUserAgreement] = useState(false);
  const [dealer, setDealer] = useState<OptionType>({
    label: '',
    value: '',
  });

  const { title, onSubmit, fieldsConfig, onCloseButton } = innerProps;

  useLockBodyScroll();

  const {
    values,
    errors,
    setErrors,
    touched,
    handleChange,
    handleSubmit,
    setFieldValue,
    isSubmitting,
  } = useFormik<ApplicationFormFieldsSchema>({
    initialValues: initialApplicationFormValues,
    validationSchema: validationApplicationSchema,
    onSubmit: async (values, { setSubmitting }) => {
      setSubmitting(true);

      try {
        await onSubmit?.(
          getResultApplicationFormValues(values, fieldsConfig)
        ).then(() => {
          setFormSend(true);
        });
      } catch (error: any) {
        const errorMap = convertRequestErrorToMap(
          error
        ) as FormikErrors<ApplicationFormFieldsSchema>;
        setErrors(errorMap);
        console.log(error);
      }

      setSubmitting(false);
    },
  });

  useEffect(() => {
    if (!fieldsConfig?.dealers?.length) {
      setFieldValue('dealer', { label: 'default', value: 'default' });
    }
  }, []);

  useUpdateEffect(() => {
    setFieldValue('dealer', dealer);
  }, [dealer]);

  return (
    <Component onSubmit={handleSubmit} noValidate autoComplete="off">
      <CloseButton
        onClick={() => {
          closeModal();
          onCloseButton?.();
        }}
      />

      {!isFormSend ? (
        <React.Fragment>
          <Title>{title}</Title>
          <Subtitle>Контактные данные</Subtitle>

          <FieldsWrapper>
            <FieldsBlock>
              <FieldsRow>
                <TextInput
                  id="name"
                  name="name"
                  label="Имя"
                  value={values.name}
                  onChange={handleChange}
                  placeholder="Имя"
                  errorMessage={
                    errors.name && touched.name ? errors.name : undefined
                  }
                />
              </FieldsRow>

              <FieldsRow>
                <TextInput
                  id="phone"
                  name="phone"
                  label="Телефон"
                  value={values.phone}
                  onChange={handleChange}
                  placeholder="+375 (__) ___-__-__"
                  errorMessage={
                    errors.phone && touched.phone ? errors.phone : undefined
                  }
                />
              </FieldsRow>
            </FieldsBlock>

            {fieldsConfig?.dealers && fieldsConfig.dealers.length !== 0 && (
              <FieldsContainer>
                <Select
                  label="Автоцентр"
                  value={dealer}
                  options={fieldsConfig.dealers}
                  onChange={setDealer}
                  errorMessage={
                    errors.dealer?.value && touched.dealer?.value
                      ? errors.dealer.value
                      : undefined
                  }
                />
              </FieldsContainer>
            )}

            <FieldsContainer>
              <TextArea
                id="message"
                name="message"
                label="Комментарий"
                value={values.message}
                onChange={handleChange}
                placeholder="Комментарий"
                errorMessage={
                  errors.message && touched.message ? errors.message : undefined
                }
              />
            </FieldsContainer>
          </FieldsWrapper>

          <CheckBoxWrapper>
            <CheckboxAgreement
              checked={userAgreement}
              onChange={() => setUserAgreement(!userAgreement)}
            />
          </CheckBoxWrapper>

          <ButtonWrapper>
            <SubmitApplication
              type="submit"
              disabled={!isSubmitting ? !userAgreement : true}
            >
              {!isSubmitting ? 'Отправить' : <Loader />}
            </SubmitApplication>
          </ButtonWrapper>
        </React.Fragment>
      ) : (
        <SuccessForm />
      )}
    </Component>
  );
}

export default ApplicationFormModal;

const Component = styled.form`
  position: relative;
  padding: 25px 40px;
  width: calc(100vw - 20px);
  max-width: 674px;
  background: ${(props) => props.theme.white};
  border-radius: 10px;

  ${media.mobile(css`
    max-width: none;
    padding: 20px;
  `)}
`;

const Title = styled.p`
  font-weight: 700;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  color: ${(props) => props.theme.black};
`;

const Subtitle = styled.p`
  font-size: 14px;
  line-height: 20px;
  margin-top: 25px;
  color: ${(props) => props.theme.black100};
`;

const FieldsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const FieldsBlock = styled.div`
  display: flex;
  flex-direction: row;

  ${media.mobile(css`
    flex-direction: column;
  `)}
`;

const FieldsRow = styled.div`
  width: 100%;

  &:not(:first-child) {
    margin-left: 24px;

    ${media.mobile(css`
      margin: 35px 0 0;
    `)}
  }
`;

const FieldsContainer = styled.div`
  margin-top: 34px;
`;

const CheckBoxWrapper = styled.div`
  display: block;
  margin-top: 20px;

  ${media.mobile(css`
    margin-top: 25px;
  `)}
`;

const ButtonWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 30px;

  ${media.mobile(css`
    margin-top: 25px;
  `)}
`;

const SubmitApplication = styled(Button)`
  position: relative;
  font-weight: 700;
  padding: 15px 0;
  width: 160px;
  background: ${colors.main};
  color: ${colors.white};
  border-radius: 14px;
  transition: 0.3s;
  height: 48px;

  ${media.mobile(css`
    width: 100%;
  `)}

  &:hover {
    background: ${colors.main100};
  }

  &:disabled {
    color: ${(props) => props.theme.black100};
    background: ${(props) => props.theme.gray400_1};
    border: 1px solid ${(props) => props.theme.gray400_2};
    opacity: 0.35;

    &:hover {
      background: ${(props) => props.theme.gray400_1};
    }
  }
`;
